import ApiService from '@/services/common/api.service'
import axios from 'axios'

const adminPayment = {
  namespaced: true,
  state: () => ({
    errors: null,
    isLoading: false,
    historyIndex: [],
    refundIndex: [],
  }),

  getters: {
    getHistoryIndex(state) {
      return state.historyIndex
    },
    getRefundIndex(state) {
      return state.refundIndex
    },
  },

  actions: {
    fetchHistoryIndex(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.get(`payment-orders?${content}`).then(
          (response) => {
            if (response && response.status === 200) {
              context.commit('setHistoryIndex', [
                ...context.state.historyIndex,
                ...response.data.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    fetchRefundIndex(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.get(`payment-orders/refund?${content}`).then(
          (response) => {
            if (response && response.status === 200) {
              context.commit('setRefundIndex', [...response.data.data])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    massUpdateRefund(context, params) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.put(`payment-orders/refund/mass-update`, params).then(
          (response) => {
            if (response && response.status === 200) {
              response.data.refunds.forEach((refund) => {
                let refundIndex = [...context.state.refundIndex]
                let index = refundIndex.findIndex((el) => el.id === refund.id)
                if (index >= 0) {
                  refundIndex[index].status = refund.transactionStatus
                }
              })
              context.commit('setRefundIndex', [...context.state.refundIndex])
              context.commit('setLoading', false)
              resolve(response.data)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    exportPaymentReport(context, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`payment-orders/export?${params}`, {
            headers: {
              'Content-Disposition': `attachment; filename=payment-report.xlsx`,
              'Content-Type':
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            responseType: 'blob',
          })
          .then(
            (response) => {
              if (response && response.status === 200) {
                resolve(response)
              }
            },
            (error) => {
              reject(error)
            }
          )
      })
    },

    exportRefundReport(context, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`payment-orders/refund/export?${params}`, {
            headers: {
              'Content-Disposition': `attachment; filename=refund-report.xlsx`,
              'Content-Type':
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            responseType: 'blob',
          })
          .then(
            (response) => {
              if (response && response.status === 200) {
                resolve(response)
              }
            },
            (error) => {
              reject(error)
            }
          )
      })
    },
  },

  mutations: {
    setHistoryIndex(state, data) {
      state.historyIndex = data
    },
    setRefundIndex(state, data) {
      state.refundIndex = data
    },
    setLoading(state, data) {
      state.isLoading = data
    },
  },
}

export default adminPayment
