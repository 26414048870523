import ApiService from '@/services/common/api.service'
export default {
  namespaced: true,
  state: () => ({}),
  actions: {
    getBilling(context, params) {
      return new Promise((resolve, reject) => {
        ApiService.get(`/auth/billing/${params}`).then(
          (response) => {
            if (response.status == 200) {
              resolve(response.data)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    getBilling2(context, params) {
      return new Promise((resolve, reject) => {
        ApiService.get(`/auth/billing/show/${params}`).then(
          (response) => {
            if (response.status == 200) {
              resolve(response.data)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    renderImage(context, params) {
      return new Promise((resolve, reject) => {
        ApiService.getFile(`/auth/payments/image/${params}`, null, {
          responseType: 'blob',
        }).then(
          (response) => {
            if (response.status == 200) {
              //const blob = response.blob()
              console.log('render response', response)

              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    getPayment(context, params) {
      return new Promise((resolve, reject) => {
        ApiService.get(`/auth/billing/${params.id}`).then(
          (response) => {
            if (response.status === 200 || response.status == 204) {
              resolve(response.data)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    getAllBillings(context, params) {
      return new Promise((resolve, reject) => {
        ApiService.get(`/auth/billing/admin`, params).then(
          (response) => {
            if (response.status === 200 || response.status == 204) {
              resolve(response.data)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    approvePayment(context, paymentID) {
      return new Promise((resolve, reject) => {
        console.log('payment id module:', paymentID)
        ApiService.put(`/auth/billing/payment/${paymentID}`, {
          test1: 'a',
        }).then(
          (response) => {
            if (response.status == 204) {
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    rejectPayment(context, paymentID) {
      return new Promise((resolve, reject) => {
        console.log('payment id module:', paymentID)
        ApiService.put(`/auth/billing/payment/reject/${paymentID}`, {
          test1: 'a',
        }).then(
          (response) => {
            if (response.status == 204) {
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    getSnapToken(context, params) {
      return new Promise((resolve, reject) => {
        ApiService.get(
          `/auth/midtrans/payment/${params.id}?payment_method=${params.paymentMethod}`
        ).then(
          (response) => {
            resolve(response.data)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    upgradePackage(context, params) {
      return new Promise((resolve, reject) => {
        ApiService.post(`/auth/package/upgrade`, params).then(
          (response) => {
            resolve(response.data)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    createPackage(context, params) {
      return new Promise((resolve, reject) => {
        ApiService.post(`/auth/package`, params).then(
          (response) => {
            resolve(response.data)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    cancelPackage(context, params) {
      return new Promise((resolve, reject) => {
        ApiService.get(`/auth/package/cancel/${params}`).then(
          (response) => {
            if (response.status == 200) {
              resolve()
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    cancelBilling(context, params) {
      return new Promise((resolve, reject) => {
        ApiService.get(`/auth/billing/cancel/${params}`).then(
          (response) => {
            if (response.status == 200) {
              resolve()
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    uploadImage(context, formData) {
      return new Promise((resolve, reject) => {
        console.log(formData.get('myFile'))
        ApiService.postImage(
          `/auth/payments/${formData.get('id')}`,
          formData
        ).then(
          (response) => {
            if (response.status == 200 || response.status == 201) {
              resolve()
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    checkPayment(context, invoice) {
      return new Promise((resolve, reject) => {
        ApiService.get(`/midtrans/check-payment/${invoice}`).then(
          (response) => {
            if (response.status == 200 || response.status == 204) {
              resolve()
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    /*
      NEW API PAYMENT
     */
    checkout(context, params) {
      return new Promise((resolve, reject) => {
        ApiService.post(`/payment/checkout`, params).then(
          (response) => {
            if (response.status === 200) {
              resolve(response.data)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    getPaymentDetail(context, orderId) {
      return new Promise((resolve, reject) => {
        ApiService.get(`/payment/${orderId}/detail`).then(
          (response) => {
            if (response.status === 200) {
              resolve(response.data)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
  },
  mutations: {},
}
