import Vue from 'vue'
import './assets/sass/app.scss'
import './registerServiceWorker'
import './plugins/validator'

import App from './App'
import './assets/sass/app.scss'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

import FrontendKit from '@roketin-library/frontend-kit'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import router from './router'
import store from './store'
import mixins from './mixins'
import ApiService from '@/services/common/api.service'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.config.productionTip = false

Vue.use(FrontendKit, {
  // platform: 'html',
})

Vue.use(VueSweetalert2)
Vue.use(VueTelInput)

ApiService.init()

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

Vue.mixin(mixins)

extend('e.164', {
  validate(value) {
    let pattern = /^\+[1-9]\d{10,14}$/
    let result = value.match(pattern)
    if (result) {
      return true
    }
    return false
  },
  message: 'Invalid phone number. Example format: +62xxxxx',
})

extend('upCase', {
  validate(value) {
    if (value && value != '') {
      let pattern = /[A-Z]/g
      let result = value.match(pattern)
      if (result) {
        return true
      }
    }
  },
  message: 'Lowercase and uppercase letters',
})

extend('number', {
  validate(value) {
    if (value && value != '') {
      let pattern = /[0-9]/g
      let result = value.match(pattern)
      if (result) {
        return true
      }
    }
  },
  message: 'at least one number',
})

extend('minPass', {
  validate(value, { length }) {
    if (value && value != '') {
      return value.length >= parseInt(length)
    }
  },
  params: ['length'],
  message: '{length} or more characters',
})
