export default [
  {
    path: '/auth',
    component: () => import('../layouts/authentication.vue'),
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('../views/Authentication/AdminLogin.vue'),
        meta: {
          title: 'Login - Rise Payment',
        },
      },
      {
        path: 'registration',
        name: 'Registration',
        component: () => import('../views/Authentication/Registration.vue'),
      },
      {
        path: 'forgot-password',
        name: 'Forgot Password',
        component: () => import('../views/Authentication/ForgotPassword.vue'),
        meta: {
          title: 'Forgot Password - Rise Payment',
        },
      },
      {
        path: 'reset-password/:token',
        name: 'Reset Password',
        component: () => import('../views/Authentication/ResetPassword.vue'),
        meta: {
          title: 'Reset Password - Rise Payment',
        },
      },
      {
        path: 'activate-account/:token',
        name: 'Activate Account',
        component: () => import('../views/Authentication/ActivateAccount.vue'),
      },
      {
        path: 'reset-password',
        name: 'Activate Account Reset Password',
        component: () => import('../views/Authentication/ResetPassword.vue'),
      },
      {
        path: 'set-password',
        name: 'Set Password',
        component: () => import('../views/Authentication/CreatePassword.vue'),
      },
    ],
  },
  {
    path: '/403',
    component: () => import('../views/ErrorPage/Forbidden.vue'),
    name: 'Forbidden',
    meta: {
      title: 'Forbidden',
    },
  },
  {
    path: '*',
    component: () => import('../views/ErrorPage/NotFound.vue'),
    name: 'NotFound',
    meta: {
      title: 'Not Found',
    },
  },
  {
    path: '/checkout',
    name: 'payment-checkout',
    component: () => import('../views/Payment/PaymentCheckout.vue'),
  },
  {
    path: '/payment-success',
    name: 'payment-success',
    component: () => import('../views/Payment/PaymentSuccess.vue'),
  },
  {
    path: '/version',
    component: () => import('../views/Version.vue'),
  },
  {
    path: '/select',
    meta: { requiresAuth: true, title: 'Space ID' },
    component: () => import('../layouts/select.vue'),
    children: [
      {
        path: 'company',
        component: () => import('../views/Select/Company/Index.vue'),
        name: 'select-company',
      },
      {
        path: 'company/add-user',
        component: () => import('../views/Select/Company/AddUser.vue'),
        name: 'add-user-via-company',
      },
      {
        path: 'product',
        component: () => import('../views/Select/Product/Index.vue'),
        name: 'select-product',
      },
    ],
  },
  {
    path: '/',
    meta: {
      requiresAuth: true,
      requiresAuth2: true,
      title: 'Space ID',
    },
    redirect: '/admin-payment',
    component: () => import('../layouts/default.vue'),
    children: [
      {
        path: '/home',
        component: () => import('../views/Home.vue'),
        name: 'home',
        children: [
          {
            path: 'dashboard',
            component: () => import('../views/Home/Index.vue'),
            name: 'dashboard',
            meta: {
              requiresCompanyID1: true,
            },
          },
          {
            path: 'path-count',
            component: () => import('../views/Home/PathCount.vue'),
            name: 'path-count',
            meta: {
              requiresCompanyID1: true,
            },
          },
          {
            path: 'company-index',
            component: () =>
              import('../views/Home/CompanyIndex/CompanyIndex.vue'),
            name: 'company-index',
            meta: {
              requiresCompanyID1: true,
            },
            children: [
              {
                path: 'active-company',
                name: 'active-company',
                component: () =>
                  import(
                    '../views/Home/CompanyIndex/ActiveCompany/IndexActiveCompany.vue'
                  ),
              },
              {
                path: 'inactive-company',
                name: 'inactive-company',
                component: () =>
                  import(
                    '../views/Home/CompanyIndex/InactiveCompany/IndexInactiveCompany.vue'
                  ),
              },
            ],
          },
        ],
      },
      {
        path: '/finish-payment',
        component: () => import('../views/FinishPayment.vue'),
        name: 'finish-payment',
      },
      //admin Roketin
      {
        path: '/questions',
        component: () => import('../views/Admin/Questions/Index.vue'),
        name: 'questions',
        meta: {
          requiresCompanyID1: true,
        },
      },
      {
        path: '/payments',
        component: () => import('../views/Admin/Payments/Payments.vue'),
        name: 'payments',
        children: [
          {
            path: 'payments',
            name: 'payments-List',
            component: () =>
              import('../views/Admin/Payments/Payments/Payments.vue'),
            children: [
              {
                path: 'dashboard',
                name: 'dashboard-payments',
                meta: {
                  requiresCompanyID1: true,
                },
                component: () =>
                  import('../views/Admin/Payments/Payments/IndexPayments.vue'),
              },
            ],
          },
        ],
      },
      {
        path: '/voucher',
        component: () => import('../views/Admin/Voucher/Voucher.vue'),
        name: 'voucher',
        children: [
          {
            path: 'voucher',
            name: 'voucher-list',
            component: () =>
              import('../views/Admin/Voucher/Voucher/Voucher.vue'),
            children: [
              {
                path: 'dashboard',
                name: 'dashboard-voucher',
                meta: {
                  requiresCompanyID1: true,
                },
                component: () =>
                  import('../views/Admin/Voucher/Voucher/IndexVoucher.vue'),
              },
              {
                path: 'create',
                name: 'create-voucher',
                meta: {
                  requiresCompanyID1: true,
                },
                component: () =>
                  import('../views/Admin/Voucher/Voucher/FormVoucher.vue'),
              },
              {
                path: 'update/:id',
                name: 'update-voucher',
                meta: {
                  requiresCompanyID1: true,
                },
                component: () =>
                  import('../views/Admin/Voucher/Voucher/FormVoucher.vue'),
              },
            ],
          },
          {
            path: 'redeemed',
            name: 'redeemed-list',
            meta: {
              requiresCompanyID1: true,
            },
            component: () =>
              import('../views/Admin/Voucher/Redeemed/Redeemed.vue'),
            children: [
              {
                path: 'dashboard',
                name: 'dashboard-redeemed',
                component: () =>
                  import('../views/Admin/Voucher/Redeemed/IndexRedeemed.vue'),
              },
            ],
          },
        ],
      },

      //user
      {
        path: 'Payment',
        component: () => import('../views/Payment/Payment.vue'),
        name: 'Payment',
        children: [
          {
            path: 'package',
            name: 'parent-package',
            component: () => import('../views/Payment/Package/Package.vue'),
            children: [
              {
                path: 'dashboard',
                name: 'dashboard-package',
                component: () => import('../views/Payment/Package/Index.vue'),
              },
              {
                path: 'checkout/:id',
                name: 'checkout-billing-package',
                component: () =>
                  import('../views/Payment/Package/Checkout.vue'),
              },
              {
                path: 'payment/:id',
                name: 'payment-billing-package',
                component: () => import('../views/Payment/Package/Payment.vue'),
              },
            ],
          },
          {
            path: 'payment',
            name: 'parent-payment',
            component: () => import('../views/Payment/Payment/Payment.vue'),
            children: [
              {
                path: 'dashboard',
                name: 'dashboard-payment',
                component: () => import('../views/Payment/Payment/Index.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'apps',
        component: () => import('../views/Apps/Apps.vue'),
        name: 'apps',
        children: [
          {
            path: 'user-management',
            name: 'parent-apps',
            component: () => import('../views/Apps/Apps/Apps.vue'),
            // component: () => import('../views/Apps/Apps/Index.vue'),
            children: [
              {
                path: ':id',
                name: 'user-management-rise',
                component: () => import('../views/Apps/Apps/Index.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'activity-log',
        component: () => import('../views/ActivityLog/ActivityLog.vue'),
        name: 'activity-log',
        children: [
          {
            path: 'login1',
            name: 'login1',
            component: () => import('../views/ActivityLog/Login/Login.vue'),
            children: [
              {
                path: 'dashboard',
                name: 'dashboard-activity-log-login',
                component: () => import('../views/ActivityLog/Login/Index.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'setting',
        component: () => import('../views/Setting/Setting.vue'),
        name: 'setting',
        children: [
          {
            path: 'user',
            name: 'setting-user',
            component: () => import('../views/Setting/User/User.vue'),
            children: [
              {
                path: 'dashboard',
                name: 'dashboard-setting-user',
                component: () => import('../views/Setting/User/Index.vue'),
              },
              {
                path: 'create',
                name: 'create-setting-user',
                component: () => import('../views/Setting/User/FormUser.vue'),
                props: true,
              },
              {
                path: 'update/:id',
                name: 'update-setting-user',
                component: () => import('../views/Setting/User/FormUser2.vue'),
                props: true,
              },
            ],
          },
        ],
      },
      {
        path: '/master-data',
        component: () => import('../views/MasterData.vue'),
        name: 'master-data',
        children: [
          {
            path: 'limit',
            component: () => import('../views/MasterData/Limit.vue'),
            children: [
              {
                path: 'cara-pembayaran',
                component: () =>
                  import('../views/MasterData/Limit/CaraPembayaran.vue'),
              },
              {
                path: 'metode',
                component: () => import('../views/MasterData/Limit/Metode.vue'),
                children: [
                  {
                    path: 'create',
                    component: () =>
                      import('../views/MasterData/Limit/Metode/Create.vue'),
                  },
                ],
              },
            ],
          },
        ],
      },
      // superuser
      {
        path: 'admin-payment',
        component: () => import('../views/AdminPayment.vue'),
        children: [
          {
            path: '',
            component: () => import('../views/AdminPayment/HistoryPayment.vue'),
            name: 'history-payment',
            meta: {
              title: 'History Payment - Roketin Payment',
            },
          },
          {
            path: 'refund',
            component: () => import('../views/AdminPayment/Refund.vue'),
            meta: {
              title: 'Refund - Roketin Payment',
            },
          },
        ],
      },
      {
        path: '/admin-management',
        component: () => import('../views/AdminManagement.vue'),
        name: 'admin-payment-management',
        meta: {
          title: 'Admin Payment Management - Roketin Payment',
        },
      },
      // {
      //   path: '/admin-payment',
      //   component: () => import('../views/AdminPayment.vue'),
      //   children: [
      //     {
      //       path: '',
      //       component: () => import('../views/AdminPayment/HistoryPayment.vue'),
      //     },
      //   ],
      // },
    ],
  },
]
