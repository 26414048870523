import ApiService from '@/services/common/api.service'

const admin = {
  namespaced: true,
  state: () => ({
    errors: null,
    isLoading: false,
    adminIndex: [],
    adminDetail: {},
  }),

  getters: {
    getAdminIndex(state) {
      return state.adminIndex
    },
    getAdminDetail(state) {
      return state.adminDetail
    },
  },

  actions: {
    fetchAdminIndex(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.get('/users', {
          perPage: content.perPage,
          page: content.page,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          search: content.search,
        }).then(
          (response) => {
            if (response && response.status === 200) {
              context.commit('setAdminIndex', [
                ...context.state.adminIndex,
                ...response.data.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            if (error.response.status === 403) {
              window.location.href = '/403'
            }
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    saveAdmin(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.post('/users', {
          name: form.name,
          email: form.email,
          status: form.status,
          role: form.role,
        }).then(
          (response) => {
            if (response.status === 200 || response.status === 201) {
              context.commit('setAdminIndex', [
                ...context.state.adminIndex,
                response.data.data,
              ])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    updateAdmin(context, data) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        const { id, form } = data

        ApiService.patch(`/users/${id}`, form, true).then(
          (response) => {
            if (response.status === 200 && context?.state?.adminIndex) {
              let AdminIndexList = [...context.state.adminIndex]

              let index = AdminIndexList.findIndex((t) => t.id === id)

              if (index >= 0) {
                context.commit('setAdminIndex', [...AdminIndexList])
                context.commit('setLoading', false)
                resolve(response)
              }
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    deleteAdmin(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.delete(`/users/${id}`).then(
          (response) => {
            if (context?.state?.adminIndex) {
              const data = context.state.adminIndex.filter((el) => el.id !== id)
              context.commit('setAdminIndex', ...data)
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },
  },

  mutations: {
    setAdminIndex(state, data) {
      state.adminIndex = data
    },
    setLoading(state, data) {
      state.isLoading = data
    },
  },
}

export default admin
