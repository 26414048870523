import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'
// import ApiService from '../services/common/api.service'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  document.querySelector('html').style.overflow = 'auto'

  let isAuthenticated = store.getters['auth/isLoggedIn']
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !isAuthenticated
  ) {
    next({ path: '/auth/login' })
  } else if (to.name == 'Login' && isAuthenticated) {
    next({ name: 'admin-payment' })
  } else if (isAuthenticated) {
    next()
  }

  next()
})

const DEFAULT_TITLE = 'Roketin Payment'
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE
  })
})

export default router
